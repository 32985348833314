import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { useDismissNotifications } from '@/lib/queries/useDismissNotifications';
import {
  UserNotification,
  UserNotificationEvent,
} from '@/types/core/UserNotification';
import { Spinner } from '@/ui/Spinner';

import { Notification } from '../Notification';

type NotificationGroupProps = {
  event: UserNotificationEvent;
  notifications: UserNotification[];
};

const summaries = {
  'task.completed': {
    one: 'Completed Task',
    many: 'Completed Tasks',
  },
  'task_comment.created': {
    one: 'Comment on Tasks',
    many: 'Comments on Tasks',
  },
  'chat_message.received': {
    one: 'Patient with unread messages',
    many: 'Patients with unread messages',
  },
  'urgent_chat_message.received': {
    one: 'Patient with urgent messages',
    many: 'Patients with urgent messages',
  },
  'scheduling.md_interval.updated': {
    one: "Patient's scheduling interval changed",
    many: "Patients' scheduling intervals changed",
  },
  'care_team_coverage.created': {
    one: 'New Care Team Coverage',
    many: 'New Care Team Coverages',
  },
};

function getSummaryDescription(event: UserNotificationEvent, count: number) {
  const summary = summaries[event];
  if (summary) {
    return count > 1 ? summary.many : summary.one;
  }
  return event;
}

function getSummary(event: UserNotificationEvent, count: number) {
  return `${count} ${getSummaryDescription(event, count)}`;
}

export const NotificationGroup = ({
  event,
  notifications,
}: NotificationGroupProps) => {
  const dismissNotifications = useDismissNotifications();
  const onDismiss = () => {
    dismissNotifications.mutate(notifications.map((n) => n.id));
  };
  return (
    <Disclosure as="div">
      {({ open }) => {
        return (
          <>
            <div className="flex w-full rounded-lg bg-green-50">
              <Disclosure.Button className="grow text-left">
                <div className="group flex flex-row items-center gap-4 p-4">
                  <div className="shrink-0">
                    <ChevronDownIcon
                      className={clsx(
                        'size-6 rounded-full text-gray-400 transition',
                        {
                          'rotate-180': open,
                          'group-hover:bg-gray-200 group-hover:text-gray-600':
                            !open,
                        },
                      )}
                    />
                  </div>
                  <div className="grow font-semibold">
                    {getSummary(event, notifications.length)}
                  </div>
                </div>
              </Disclosure.Button>
              <button
                className="p-4"
                onClick={onDismiss}
                disabled={dismissNotifications.isLoading}
              >
                {dismissNotifications.isLoading ? (
                  <Spinner className="size-4 text-gray-400" />
                ) : (
                  <XMarkIcon className="size-6 text-gray-400" />
                )}
              </button>
            </div>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel
                static
                className="mt-3 flex flex-col gap-3 pl-6"
              >
                {notifications.map((notification) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                  />
                ))}
              </Disclosure.Panel>
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
};
