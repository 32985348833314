export const VisitTypeSlug = {
  Any: '',
  Initial: 'initial',
  Return: 'return',
  Transition: 'transition',
} as const;

export type VisitTypeSlug = (typeof VisitTypeSlug)[keyof typeof VisitTypeSlug];

export type VisitType = {
  durationMinutes: number;
  startInterval: number;
};
